/*Usage:
font-size:rm(32, 16); // => font-size:2rm;
*/
@media screen and (min-width: 429px) {
  html {
    font-size: 18px;
  }
}
@media screen and (min-width: 641px) {
  html {
    font-size: 27px;
  }
}
@media screen and (min-width: 761px) {
  html {
    font-size: 32px;
  }
}
@media screen and (min-width: 811px) {
  html {
    font-size: 9px;
  }
  h1 {
    margin: 0;
    font-size: 10rem;
    line-height: 1.33125;
  }
  h2 {
    margin: 0;
    font-size: 5rem;
    line-height: 1.333;
  }
  h3 {
    margin: 0;
    font-size: 5rem;
    line-height: 1.2;
  }
  p {
    font-size: 2.5rem;
    line-height: 1.333;
  }
  .main-btn {
    min-height: 3.0625rem;
    padding: 0.3125rem 2rem;
    font-size: 1.125rem;
  }
  .form-fields {
    gap: 0.75rem 1rem;
  }
  .form-fields .w-50 {
    width: calc(50% - 1rem / 2);
  }
  header {
    padding: 1.5rem;
  }
  header .logo-wrap img {
    max-height: 6.125rem;
  }
  header .btn-wrap .main-btn .only-dt {
    display: inline;
  }
  .page-title {
    padding: 0 3rem 2.25rem 3rem;
  }
  .iconic-club .club-info,
  .private-lounge,
  .entertain-place .place-text,
  .business-lounge {
    padding: 7.5rem 12.375rem;
  }
  .private-lounge .lounge-text,
  .business-lounge .lounge-text {
    gap: 2rem;
  }
  .entertain-place .place-text p,
  .ballroom-place .place-text p {
    font-size: 3.875rem;
    line-height: 1.2259;
  }
  .iconic-club {
    display: flex;
  }
  .iconic-club .club-img {
    width: 58%;
  }
  .iconic-club .club-img:before {
    padding-bottom: 128.353%;
  }
  .iconic-club .club-info {
    gap: 3.5rem;
    justify-content: center;
    flex-direction: column-reverse;
    width: 42%;
    padding: 5.75rem 5rem 2.125rem 5rem;
  }
  .iconic-club .club-info .logo-box {
    padding: 2.3125rem 2.75rem;
  }
  .iconic-club .club-info .logo-box img {
    max-height: 14.0625rem;
  }
  .private-lounge .lounge-text p {
    padding: 0 1.625rem;
  }
  .entertain-place .place-text p {
    padding: 0 1.75rem;
  }
  .entertain-place .place-img:before {
    padding-bottom: 54.375%;
  }
  .exclusive-escape,
  .familiar-friends .friends-data {
    padding: 7.5rem 10rem;
  }
  .exclusive-escape {
    gap: 5rem;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: unset;
  }
  .exclusive-escape .escape-title {
    width: 100%;
  }
  .exclusive-escape .escape-title h3 {
    font-size: 6rem;
    line-height: 1.35;
  }
  .exclusive-escape .escape-img,
  .exclusive-escape .escape-info {
    width: calc(50% - 5rem / 2);
  }
  .exclusive-escape .escape-img:before {
    padding-bottom: 121.924%;
  }
  .exclusive-escape .escape-info {
    margin: -0.8125rem 0;
  }
  .exclusive-escape .escape-info p {
    font-size: 3rem;
    line-height: 1.25;
  }
  .familiar-friends .friends-data {
    gap: 4rem;
  }
  .familiar-friends .friends-title {
    gap: 1.5rem;
  }
  .familiar-friends .friends-title h3 {
    font-size: 6rem;
    line-height: 1;
  }
  .familiar-friends .friends-title p {
    max-width: 34.375rem;
    margin: 0 auto;
    font-size: 1.75rem;
    line-height: 1.4287;
  }
  .familiar-friends .friends-slider .swiper .swiper-slide {
    padding: 0 2.5rem;
  }
  .familiar-friends .friends-slider img {
    max-height: 5.6875rem;
  }
  .familiar-friends .friends-img:before {
    padding-bottom: 58.3334%;
  }
  .ballroom-place {
    display: flex;
  }
  .ballroom-place .place-text,
  .ballroom-place .place-img {
    width: 50%;
  }
  .ballroom-place .place-text {
    padding: 5.625rem 2.25rem;
  }
  .ballroom-place .place-text h3 {
    font-size: 6rem;
    line-height: 1.333;
  }
  .ballroom-place .place-text p {
    font-size: 3rem;
    line-height: 1.0834;
  }
  .ballroom-place .place-img:before {
    padding-bottom: 100%;
  }
  .ballroom-place .place-img.only-dt {
    display: block;
  }
  .business-lounge .lounge-text {
    min-height: 23rem;
  }
  .business-lounge .lounge-text h3 {
    font-size: 6rem;
    line-height: 1.333;
  }
  .boutique-place {
    display: flex;
  }
  .boutique-place .place-text,
  .boutique-place .place-img {
    width: 50%;
    order: unset;
  }
  .boutique-place .place-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5rem 4.75rem;
    text-align: center;
  }
  .boutique-place .place-img {
    padding: 7.5rem;
  }
  .boutique-place .place-img + .place-text h3 {
    font-size: 6rem;
    line-height: 1.333;
  }
  .contact-host {
    padding: 4rem 7.5rem;
  }
  .contact-host .contact-info {
    gap: 1.75rem;
    padding: 3rem 9.75rem;
  }
  .contact-host .contact-info .contact-title {
    gap: 1.875rem;
  }
  .contact-host .contact-info .contact-title h3 {
    font-size: 4rem;
    line-height: 0.875;
  }
  .contact-host .contact-info .contact-title p {
    font-size: 1.125rem;
    line-height: 1.6;
  }
  .contact-host .contact-info .submit-btn {
    text-align: center;
  }
  .contact-host .contact-info .submit-btn .main-btn {
    width: unset;
    padding: 0.625rem 5rem;
  }
  footer {
    padding: 0 2rem;
  }
  footer .wrap-box {
    padding-right: 5rem;
  }
}
@media screen and (min-width: 992px) {
  html {
    font-size: 10px;
  }
}
@media screen and (min-width: 1081px) {
  html {
    font-size: 11px;
  }
}
@media screen and (min-width: 1161px) {
  html {
    font-size: 12px;
  }
}
@media screen and (min-width: 1251px) {
  html {
    font-size: 13px;
  }
}
@media screen and (min-width: 1401px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 1441px) {
  html {
    font-size: 17px;
  }
}
@media screen and (min-width: 1541px) {
  html {
    font-size: 18px;
  }
}