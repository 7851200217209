/*Usage:
font-size:rm(32, 16); // => font-size:2rm;
*/
:root {
  --transition: 0.3s ease-in-out;
  --base-black: #000000;
  --base-white: #FFFFFF;
  --main-blue: #1955DE;
}

html {
  font-size: 16px;
  overflow-x: hidden;
}

html {
  overflow: auto;
  background: #080808;
}

body {
  position: relative;
  min-width: 320px;
  color: #F8F8F3;
  font-family: "Playfair Display", serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  backface-visibility: hidden;
  text-rendering: geometricPrecision;
  font-optical-sizing: auto;
}

* {
  outline: none;
  word-wrap: break-word;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

hr {
  margin: 0;
  border: 1px solid var(--web-gray-30-color);
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
}

button {
  padding: 0;
  cursor: pointer;
}

input,
button,
textarea {
  border-radius: 0;
  -webkit-appearance: none;
}

select {
  -webkit-appearance: none;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}
.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}
.lenis.lenis-stopped {
  overflow: hidden;
}
.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

/*.txt-anim {
  overflow: hidden;
}*/
span.char {
  position: relative;
  z-index: 1;
}

.only-dt {
  display: none;
}

.main-btn {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  min-height: 2.875rem;
  padding: 0.3125rem 1.5rem;
  font-family: "Helvetica", sans-serif;
  color: #080808;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  background-color: #F8F8F3;
  border: 1px solid #F8F8F3;
  border-radius: 0.125rem;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.main-btn.tp-1 {
  color: #F8F8F3;
  background-color: #080808;
  border-color: #080808;
}
h1 {
  margin: 0;
  font-size: 2.8125rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.0625rem;
}

h2 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 1.3334;
  letter-spacing: -0.0625rem;
}

h3 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.325;
  letter-spacing: -0.0625rem;
}

p {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.333;
  letter-spacing: -0.0625rem;
}

.form-fields {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  font-family: "Helvetica", sans-serif;
  /*.submit-bth {
    width: 100%;
    margin-top: rm(16);

    .main-btn {
      width: 100%;
    }
  }*/
}
.form-fields label {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  /*input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &:checked {
      ~ span {
        &::before {
          color: var(--main-blue);
          border-color: var(--main-blue);
        }
      }
    }

    ~ span {
      !*     gap: rm(8);
           display: flex;
           align-items: center;*!
      display: block;
      padding-left: rm(36);
      font-size: rm(14);
      font-weight: 400;
      line-height: 1.429;

      cursor: pointer;
      position: relative;
      z-index: 1;

      a {
        font-weight: 500;
        color: var(--main-blue);
        text-decoration: underline;
      }

      &::before {
        font-family: $IconFont;
        content: "\e901";
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: rm(24);
        height: rm(24);
        margin-right: rm(12);
        font-size: rm(20);
        line-height: 1;
        color: transparent;
        background: var(--base-white);
        border: 1px solid rgba(220, 223, 234, 0.7);
        border-radius: rm(4);
        position: absolute;
        top: rm(-2);
        left: 0;
        transition: color $transition, border-color $transition,
        background-color $transition;
      }
    }
  }

  input[type="radio"] {
    &:checked {
      ~ span {
        &::before {
          color: transparent;
          background: radial-gradient(circle at center, #1955DE 36%, #ffffff 0%);
        }
      }
    }

    ~ span {
      &::before {
        border-radius: 50%;
      }
    }
  }*/
  /*span.select {
    display: block;
    position: relative;
    z-index: 1;

    &:after {
      font-family: $IconFont;
      content: "\e921";
      display: flex;
      align-items: center;
      font-size: rm(20);
      position: absolute;
      top: 0;
      right: rm(21);
      bottom: 0;
      z-index: 2;
      pointer-events: none;
    }

    select {
      padding-right: rm(45);
    }
  }*/
  /*&.error {
    input,
    textarea {
      !*border-color: var(--web-secondary-color);*!
    }

    input[type="checkbox"],
    input[type="radio"] {
      ~ span {
        &::before {
          color: transparent;
          !*border-color: var(--web-secondary-color);
          background: var(--web-bg-color);*!
        }
      }
    }

    span.field {
      [class^="ic-"],
      [class*=" ic-"] {
        display: none;
      }

      !*&::before {
        font-family: $IconFont;
        content: "\e901";
        position: absolute;
        top: 50%;
        right: rm(14);
        z-index: 2;
        font-size: rm(16);
        color: var(--web-secondary-color);
        transform: translateY(-50%);
      }*!
    }
  }*/
}
.form-fields label input,
.form-fields label select,
.form-fields label textarea {
  width: 100%;
  padding: 0.875rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.286;
  background: none;
  border: 1px solid rgba(8, 8, 8, 0.08);
  box-shadow: 0 0 0.75rem 0 rgba(255, 255, 255, 0.2) inset;
}
.form-fields label input,
.form-fields label select,
.form-fields label span.field span.label {
  height: 3rem;
}
.form-fields label textarea {
  height: 9.75rem;
  resize: none;
}
.form-fields label span.field {
  display: block;
  position: relative;
  z-index: 1;
  /*[class^="ic-"],
  [class*=" ic-"] {
    display: flex;
    align-items: center;
    padding: rm(4);
    font-size: rm(24);
    position: absolute;
    top: 50%;
    right: rm(15);
    z-index: 2;
    transform: translateY(-50%);

    ~ {
      input,
      select,
      textarea {
        padding-right: rm(40);
      }
    }
  }*/
}
.form-fields label span.field input,
.form-fields label span.field select,
.form-fields label span.field textarea {
  /*  &:not(:placeholder-shown) {
      border-color: red;
    }*/
}
.form-fields label span.field input:focus ~ span.label,
.form-fields label span.field select:focus ~ span.label,
.form-fields label span.field textarea:focus ~ span.label {
  opacity: 0;
  visibility: hidden;
}
.form-fields label span.field span.label {
  display: flex;
  gap: 0.375rem;
  align-items: center;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.form-fields label span.field span.label sup {
  top: 0;
  color: #FF0000;
  font-size: 1.125rem;
}
.form-fields label span.field .num-code {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  position: relative;
  z-index: 1;
}
.form-fields label span.field .num-code:before {
  content: "";
  position: absolute;
  top: 0;
  left: 3.375rem;
  bottom: 0;
  border-right: 1px solid #DCDFEA;
}
.form-fields label span.field .num-code img {
  width: 2.625rem;
  height: 1.625rem;
  border-radius: 0.25rem;
  border: 1px solid #DCDFEA;
}
.form-fields label.tel-code {
  display: flex;
  gap: 0.75rem;
}
.form-fields label.tel-code span.field:nth-child(1) {
  width: 4.0625rem;
}
.form-fields label.tel-code span.field:nth-child(1) input,
.form-fields label.tel-code span.field:nth-child(1) select,
.form-fields label.tel-code span.field:nth-child(1) textarea {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  text-align: center;
}
.form-fields label.tel-code span.field:nth-child(2) {
  width: calc(100% - 4.0625rem - 0.75rem);
}
.form-fields label.disabled {
  pointer-events: none;
  opacity: 0.8;
}

header {
  padding: 1.5rem 1rem;
}
header .wrap-box {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
}
header .logo-wrap a {
  display: block;
}
header .logo-wrap img {
  max-height: 4.5rem;
}

.page-title {
  padding: 0.5rem 1rem 0.9375rem 1rem;
}

.iconic-club .club-info,
.private-lounge,
.entertain-place .place-text,
.exclusive-escape,
.familiar-friends .friends-data,
.business-lounge {
  padding: 5rem 1rem;
  text-align: center;
}

.iconic-club .club-img,
.entertain-place .place-img,
.exclusive-escape .escape-img,
.familiar-friends .friends-img,
.ballroom-place .place-img,
.boutique-place .place-img .img-wrap {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.iconic-club .club-img:before,
.entertain-place .place-img:before,
.exclusive-escape .escape-img:before,
.familiar-friends .friends-img:before,
.ballroom-place .place-img:before,
.boutique-place .place-img .img-wrap:before {
  content: "";
  display: block;
  pointer-events: none;
}
.iconic-club .club-img img,
.entertain-place .place-img img,
.exclusive-escape .escape-img img,
.familiar-friends .friends-img img,
.ballroom-place .place-img img,
.boutique-place .place-img .img-wrap img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  transform: scale(1.28);
  transform-origin: 50% 50%;
}

.entertain-place .place-text p,
.exclusive-escape .escape-info p,
.ballroom-place .place-text p {
  font-size: 2rem;
  line-height: 1.3438;
}

.iconic-club .club-img:before {
  padding-bottom: 96%;
}
.iconic-club .club-info {
  display: flex;
  gap: 2.25rem;
  flex-direction: column;
}
.iconic-club .club-info .logo-box {
  padding: 1.0625rem 1.3125rem;
}
.iconic-club .club-info .logo-box img {
  max-height: 7.875rem;
}

.private-lounge,
.business-lounge {
  position: relative;
  z-index: 1;
}
.private-lounge .lounge-img,
.business-lounge .lounge-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
  pointer-events: none;
}
.private-lounge .lounge-img:before,
.business-lounge .lounge-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: rgba(8, 8, 8, 0.7);
}
.private-lounge .lounge-img img,
.business-lounge .lounge-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.private-lounge .lounge-text,
.business-lounge .lounge-text {
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-direction: column;
  min-height: 8.3125rem;
}

.entertain-place .place-text {
  color: #080808;
  background: #F8F8F3;
}
.entertain-place .place-img:before {
  padding-bottom: 85.334%;
}

.exclusive-escape {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  color: #080808;
  background: #F8F8F3;
}
.exclusive-escape .escape-title {
  text-align: left;
}
.exclusive-escape .escape-img:before {
  padding-bottom: 81.634%;
}
.familiar-friends {
  overflow: hidden;
}
.familiar-friends .friends-data {
  display: flex;
  gap: 3rem;
  flex-direction: column;
}
.familiar-friends .friends-title {
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
}
.familiar-friends .friends-slider .swiper {
  overflow: visible;
}
.familiar-friends .friends-slider .swiper .swiper-wrapper {
  transition-timing-function: linear;
}
.familiar-friends .friends-slider .swiper .swiper-slide {
  width: auto;
  padding: 0 1rem;
}
.familiar-friends .friends-slider img {
  max-height: 3rem;
}
.familiar-friends .friends-img:before {
  padding-bottom: 85.334%;
}

.ballroom-place .place-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7rem 1rem;
  color: #080808;
  text-align: center;
  background: #F8F8F3;
}
.ballroom-place .place-text:last-child {
  color: #F8F8F3;
  background: #B8A28D;
}
.ballroom-place .place-img:before {
  padding-bottom: 73.867%;
}

.boutique-place {
  display: flex;
  flex-wrap: wrap;
}
.boutique-place .place-text {
  order: 1;
  width: 100%;
  padding: 7.5rem 1rem;
  text-align: center;
  position: relative;
  z-index: 1;
}
.boutique-place .place-text .img-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
  pointer-events: none;
}
.boutique-place .place-text .img-bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: rgba(8, 8, 8, 0.6);
}
.boutique-place .place-text .img-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.boutique-place .place-text .text-box {
  color: #F8F8F3;
}
.boutique-place .place-img {
  order: 2;
  width: 100%;
  padding: 3rem;
  background: #F8F8F3;
}
.boutique-place .place-img .img-wrap:before {
  padding-bottom: 100%;
}

.contact-host {
  padding: 5rem 1rem;
  position: relative;
  z-index: 1;
}
.contact-host .img-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
  pointer-events: none;
}
.contact-host .img-bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.contact-host .img-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contact-host .contact-info {
  display: flex;
  gap: 1.75rem;
  flex-direction: column;
  max-width: 60.25rem;
  margin: 0 auto;
  padding: 2rem 1rem;
  color: #080808;
  background: #F8F8F3;
  border-radius: 0.125rem;
}
.contact-host .contact-info .contact-title {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  text-align: center;
}
.contact-host .contact-info .contact-title p {
  font-size: 0.875rem;
  line-height: 1.3577;
  letter-spacing: unset;
}
.contact-host .contact-info .contact-socials {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  text-align: center;
}
.contact-host .contact-info .contact-socials h5 {
  margin: 0;
  font-family: "Helvetica", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
}
.contact-host .contact-info .contact-socials ul {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  justify-content: center;
}
.contact-host .contact-info .contact-socials ul li {
  font-size: 1.5rem;
}
.contact-host .contact-info .contact-socials ul li a {
  color: inherit;
}
.contact-host .contact-info .contact-more {
  text-align: center;
}
.contact-host .contact-info .contact-more p {
  margin: 0;
  font-family: "Helvetica", sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1;
}
.contact-host .contact-info .submit-btn {
  margin-top: 0.5rem;
}
.contact-host .contact-info .submit-btn .main-btn {
  width: 100%;
  min-height: 3rem;
  font-family: "Helvetica", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
}

footer {
  padding: 0.375rem 1rem;
}
footer .wrap-box {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;
  font-family: "Helvetica", sans-serif;
  text-transform: uppercase;
}
footer .wrap-box ul {
  display: flex;
  flex-wrap: wrap;
}
footer .wrap-box ul li {
  font-size: 0.625rem;
  font-weight: 300;
  line-height: 1.2;
}
footer .wrap-box ul li a {
  display: flex;
  padding: 1.375rem 1rem;
  color: inherit;
}
footer .wrap-box p {
  padding: 1rem 0;
  font-size: 0.625rem;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: unset;
}